// extracted by mini-css-extract-plugin
export var onlineMarketplaceAchievementsSection = "y_dK";
export var onlineMarketplaceCardBorderSection = "y_ds";
export var onlineMarketplaceCardRowIconsSection = "y_dy";
export var onlineMarketplaceClientQuotesSection = "y_dL";
export var onlineMarketplaceCycleDevSection = "y_dt";
export var onlineMarketplaceCycleDevSection__testimonial = "y_dv";
export var onlineMarketplaceDomainExpertiseSection = "y_dw";
export var onlineMarketplaceExceptionSection = "y_dx";
export var onlineMarketplaceFeaturedCaseSection = "y_dz";
export var onlineMarketplaceFeaturesSection = "y_dC";
export var onlineMarketplaceFeaturesSection__box = "y_dD";
export var onlineMarketplaceRequirementSection = "y_dF";
export var onlineMarketplaceSuccessStoriesSection = "y_dH";
export var onlineMarketplaceSuccessfulSection = "y_dB";
export var onlineMarketplaceTechStackSection = "y_dG";
export var onlineMarketplaceWhyHireSection = "y_dJ";