import { ServicesProcessMock } from '~/types/mock'

const OMDProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: 'Research & discovery',
    descr:
      'This stage in the development of a marketplace helps our expert team understand your business goals and target audience, and suggest the best ways to address them.',
  },
  {
    num: '02',
    title: 'Product Design (UX and UI)',
    descr:
      'We design prototypes and UI for your future marketplace based on the product goals and target audience. This helps us implement the user journey and build an intuitive custom app that your users will love.',
  },
  {
    num: '03',
    title: 'Product (MVP) development',
    descr:
      'Our experienced software developers build your marketplace based on the latest industry standards. Your platform will be secure, scalable, and maintainable allowing to add new functionality when needed.',
  },
  {
    num: '04',
    title: 'Optimization and QA',
    descr:
      'By putting special effort into testing each marketplace website we create, and optimizing the code, we are able to ensure high quality and efficiency of all the custom solutions our team delivers.',
  },
  {
    num: '05',
    title: 'Deploy, monitoring and support',
    descr:
      'We monitor your marketplace once it’s deployed, and quickly solve any issues that may arise. If you don’ t have an in-house tech team, we will take care of your platform and support it on a regular basis.',
  },
]

export default OMDProcess
