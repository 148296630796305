import { WithOptional } from '~/types/helper'
import { ExpertiseSectionMock } from '~/types/mock'

const OMDServices: WithOptional<ExpertiseSectionMock, 'description'>[] = [
  {
    icon: 'spriteIcon_22',
    title: 'Intuitive UX',
  },
  {
    icon: 'spriteIcon_23',
    title: 'Beautiful UI',
  },
  {
    icon: 'spriteIcon_24',
    title: 'Fast-loading',
  },
  {
    icon: 'spriteIcon_25',
    title: 'Mobile-friendly',
  },
]

export default OMDServices
