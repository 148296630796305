import { BaseMock } from '~/types/mock'

const OMDWhyChoose: BaseMock[] = [
  {
    title: 'Save costs and launch quickly',
    description:
      'Our development process is efficient and fully transparent, allowing us to build your marketplace on time and on budget.',
  },
  {
    title: 'Boost conversion rate and sales',
    description:
      'We carefully align user journey with an intuitive UX design, resulting in highly engaging platforms that help you win customers.',
  },
  {
    title: 'SEO-optimized solution',
    description:
      'We build marketplace websites which are mobile-friendly, rank higher, and boost traffic thanks to Google’s mobile-first principle.',
  },
  {
    title: 'Best industry standards',
    description:
      'We follow all marketplace industry trends, share expertise with you, and adapt our development process to the best standards.',
  },
  {
    title: 'Enthusiastic team that cares',
    description:
      'We are proud of our result-oriented team that values communication, flexibility, and focuses on high-value relationships that last.',
  },
  {
    title: 'Support after launch',
    description:
      'Our marketplace development service doesn’t end when your website goes live. Codica’s reliable support team will ensure your platform runs smoothly.',
  },
]

export default OMDWhyChoose
