import { FAQ } from '~/types/data-array'

const OMDFAQ: FAQ[] = [
  {
    question:
      'What is the estimated timeframe for developing an online marketplace?',
    answer:
      'We are able to develop marketplaces fast and efficiently. Generally, the development of a basic online marketplace may take from 3 to 6 months.',
  },
  {
    question:
      'What factors determine the cost of developing an online marketplace?',
    answer:
      'The cost of developing an online marketplace would vary depending on some factors. All these play an important role in determining the overall price: complexity of features and functionalities you want to be included, the number of users supported by the platform, hosting and infrastructure needs.',
  },
  {
    question:
      'How flexible is the development process regarding changes in requirements?',
    answer:
      'A development process for an online marketplace may be amenable to change if the project has evolved. But remember that changes along the way may affect the timeline and budget, so we recommend you work closely with our expert team to handle changes.',
  },
  {
    question:
      'What is included in the cost estimate for an online marketplace project?',
    answer:
      'The cost of an online marketplace project includes the development of the website or mobile app and the infrastructure necessary to host and maintain the platform, together with ongoing operational expenditures for hosting, maintenance, and customer support.',
  },
  {
    question: 'Are there ongoing costs after the marketplace is launched?',
    answer:
      'Yes, there are ongoing costs once an online marketplace is up and running. These costs include hosting fees, maintenance, and software updates. These factors are valuable considerations when planning for the long-term sustainability of your marketplace.',
  },
]

export default OMDFAQ
