import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { Item } from '~/types/data-array'

import * as containerStyles from './ServicesSuccessfulSection.module.scss'

interface ServicesSuccessfulSectionProps {
  imageOne: IGatsbyImageData | undefined
  imageTwo: IGatsbyImageData | undefined
  list: Item[]
}

const ServicesSuccessfulSection = ({
  imageOne,
  imageTwo,
  list,
}: ServicesSuccessfulSectionProps) => (
  <div className="container container-md">
    <div className="row">
      <div className="col-12 col-lg-6">
        <div
          className={containerStyles.servicesSuccessfulSection__imageWrapper}
        >
          {imageOne && (
            <GatsbyImage
              image={imageOne}
              alt="Codica team members working on a new project"
              title="Codica employees working on a new project"
              loading="lazy"
              className={
                containerStyles.servicesSuccessfulSection__imageWrapperFirst
              }
            />
          )}
          {imageTwo && (
            <GatsbyImage
              image={imageTwo}
              alt="Services that suit your requirement"
              title="Services tailored for your needs"
              loading="lazy"
              className={
                containerStyles.servicesSuccessfulSection__imageWrapperSecond
              }
            />
          )}
        </div>
      </div>
      <div className="col-12 col-lg-6">
        <ul className={containerStyles.servicesSuccessfulSection__list}>
          {list.map(({ item }) => (
            <li
              key={String(item)}
              className={containerStyles.servicesSuccessfulSection__listItem}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
)

export default ServicesSuccessfulSection
