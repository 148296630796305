import { ExpertiseSectionMock } from '~/types/mock'

const OMDSolutions: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_26',
    title: 'Business to Business (B2B)',
  },
  {
    icon: 'spriteIcon_27',
    title: 'Consumer to Consumer (C2C)',
  },
  {
    icon: 'spriteIcon_28',
    title: 'Multi-vendor marketplace',
    link: '/services/multi-vendor-marketplace-development/',
  },
  {
    icon: 'spriteIcon_29',
    title: 'Business to Consumer (B2C)',
  },
  {
    icon: 'spriteIcon_30',
    title: 'Peer to Peer (P2P)',
  },
  {
    icon: 'spriteIcon_31',
    title: 'Market network',
  },
  {
    icon: 'spriteIcon_9',
    title: 'eCommerce Marketplace',
    link: '/services/ecommerce-marketplace-development/',
  },
]

export default OMDSolutions
