import { getImage, StaticImage } from 'gatsby-plugin-image'

import CompanyWhyHireBlock from '~/components/blocks/CompanyWhyHireBlock'
import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import Testimonial from '~/components/blocks/TestimonialPost'
import CallToAction from '~/components/call-to-actions/CallToAction'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import DiscoverWorksItem from '~/components/items/DiscoverWorksItem'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import SuccessStoriesSliderSection from '~/components/sections/SuccessStoriesSliderSection'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/online-marketplace-development/Anchors'
import OMDFAQ from '~/mock/services/online-marketplace-development/OMDFAQ'
import OMDIndustries from '~/mock/services/online-marketplace-development/OMDIndustries'
import OMDProcess from '~/mock/services/online-marketplace-development/OMDProcess'
import OMDServices from '~/mock/services/online-marketplace-development/OMDServices'
import OMDSolutions from '~/mock/services/online-marketplace-development/OMDSolutions'
import OMDWhyChoose from '~/mock/services/online-marketplace-development/OMDWhyChoose'
import RequirementsSoftware from '~/mock/services/RequirementsSoftware'
import { fileToImageLikeData } from '~/utils'
import AwardsServicesSection from '~/views/Services/components/AwardsServicesSection'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import ServicesRequirementsSoftware from '~/views/Services/components/ServicesRequirementsSoftware'
import ServicesSectionBeforeTestimonials from '~/views/Services/components/ServicesSectionBeforeTestimonials'
import ServicesSuccessfulSection from '~/views/Services/components/ServicesSuccessfulSection'
import ServicesTechStackSection from '~/views/Services/components/ServicesTechStackSection'
import useOnlineMarketplaceStaticQuery from '~/views/Services/OnlineMarketplace/useOnlineMarketplaceStaticQuery'

import * as containerStyles from './OnlineMarketplace.module.scss'

const clientName = 'CEO'

const OnlineMarketplaces = () => {
  const query = useOnlineMarketplaceStaticQuery()

  const clientImpact = getImage(fileToImageLikeData(query.clientImpact))
  const portfolioAfricar = getImage(fileToImageLikeData(query.portfolioAfricar))
  const tailoredOne = getImage(fileToImageLikeData(query.tailoredOne))
  const tailoredTwo = getImage(fileToImageLikeData(query.tailoredTwo))
  const tailoredThree = getImage(fileToImageLikeData(query.tailoredThree))
  const tailoredFour = getImage(fileToImageLikeData(query.tailoredFour))
  const tailoredFive = getImage(fileToImageLikeData(query.tailoredFive))
  const quoteCodicaInessaPhoto = getImage(
    fileToImageLikeData(query.quoteCodicaInessaPhoto),
  )

  return (
    <MainLayout scrollTop>
      <PrimeSectionServices
        titleTop="Online Marketplace"
        title="Development Services"
        description="Have a great idea for a custom marketplace? We know how to create a platform that your users will love."
        breadcrumbSecondLevel="Services"
        breadcrumbSecondLevelPath="/services/"
        breadcrumbCurrent="Online Marketplace Development Services"
        breadcrumbCurrentPath="/services/online-marketplace-development/"
      />

      <StickyNavigation anchors={Anchors} />

      <section
        className={containerStyles.onlineMarketplaceCardBorderSection}
        id="services"
      >
        <HeadSection
          position="center"
          title="Create an outstanding marketplace"
          description="Looking to build the next Amazon or Airbnb and disrupt your industry? With online marketplace development services, Codica builds optimized and engaging platforms that bring a great volume of traffic, increase conversion, and grow sales. We will help you create a successful, scalable, and high-quality custom marketplace website that your users will enjoy."
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard isColSm isBorder dataContent={OMDServices} />
      </section>

      <section className={containerStyles.onlineMarketplaceCycleDevSection}>
        <HeadSection
          position="center"
          title="Marketplace development experts"
          description="Having extensive experience in marketplace website development from scratch, our expert team knows how to make your platform outstanding."
          descriptionLarge
        />
        <ServicesSectionBeforeTestimonials
          imageOne={tailoredOne}
          imageTwo={tailoredTwo}
          content={[
            {
              text: 'You need more than just a cost-effective online marketplace platform. You need a product which will ensure a great volume of traffic from search engines and a high conversion rate, thus helping you win customers and grow your business.',
            },
            {
              text: 'Being an experienced online marketplace development company, we know that it is vitally important to focus on your end users. This is why we thoroughly analyze your target audience and their expectations, align them with UX and UI design, and deliver highly engaging and profitable marketplaces.',
            },
            {
              text: 'Codica will quickly and efficiently create a marketplace website for you, corresponding to the latest industry and tech trends and recommendations. And what’s most important - your customers will love it.',
            },
          ]}
        />

        <div
          className={
            containerStyles.onlineMarketplaceCycleDevSection__testimonial
          }
        >
          <Testimonial
            image={quoteCodicaInessaPhoto}
            name="Inessa"
            position="UI/UX Lead at Codica"
            quote={[
              {
                text: '“UX design is extremely important in online marketplaces. A beautiful interface is not enough anymore, users prefer websites that are as intuitive as possible and were built with their needs in mind.',
              },
              {
                text: 'This is why our team pays special attention to the user journey when we create marketplace websites.”',
              },
            ]}
          />
        </div>

        <CallToAction
          title="Have a great idea for a custom marketplace?"
          subtitle="We will help you stand out from the crowd!"
          classNameForGA="servicesCTA_1"
          linkName="Talk to an expert"
          link="/contacts/"
        />
      </section>

      <section
        className={containerStyles.onlineMarketplaceDomainExpertiseSection}
        id="industries"
      >
        <HeadSection
          position="center"
          title="Domain expertise you can rely on"
          description="Our experience of marketplace website development for particular industries has allowed us to grow in-house expertise which we gladly share with our customers. We provide consultations on every stage of the project development and know how to build a marketplace following the best industry standards."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={OMDIndustries} />
      </section>

      <section
        className={containerStyles.onlineMarketplaceExceptionSection}
        id="process"
      >
        <HeadSection
          position="center"
          title="Here’s how we develop exceptional custom marketplaces"
        />
        <ServicesProcess dataContent={OMDProcess} />
        <SubtractBlock isTop />
      </section>

      <section
        className={containerStyles.onlineMarketplaceCardRowIconsSection}
        id="solutions"
      >
        <HeadSection
          position="center"
          title="Building unique and engaging marketplace websites"
          description="Whether you need to create a B2B marketplace or a multi-vendor marketplace platform, we can help you with any marketplace website development."
          descriptionLarge
        />

        <ServicesIconSmallCard isBorder dataContent={OMDSolutions} />
      </section>

      <section className={containerStyles.onlineMarketplaceFeaturedCaseSection}>
        <HeadSection position="center" title="Featured case study" />
        <DiscoverWorksItem
          gradient="gradientAfricar"
          image={portfolioAfricar}
          header="Multi-vendor vehicle marketplace"
          title="This is the first vehicle marketplace platform in Africa, and it consists of 90+ independent marketplaces, fully localized for each country. The marketplace was initially designed, developed, and optimized with focus on mobile experience."
          resultsLeftTitle="90+"
          resultsLeft="online marketplaces operating as part of the platform"
          resultsRightTitle="430%"
          resultsRight="traffic growth for the past 2 years thanks to SEO-optimised website"
          link="multi-vendor-vehicle-marketplace"
          review={[
            '“For now, we have been working with Codica for 3 years already, and the results have been great. The initial website was perfect to raise investment, and we further developed it into a complex and high-performing marketplace platform.',
            <div key="featuredCase" style={{ paddingTop: 10 }} />,
            'These guys know how to deliver a high-quality product on time and on budget. I highly recommend Codica, the team is highly professional and very dedicated.”',
          ]}
          clientPhoto={
            <StaticImage
              src="../../../assets/images/avatars/AvatarDraft.png"
              alt={`${clientName} | Codica`}
              title={clientName}
              width={50}
              height={50}
            />
          }
          clientName={clientName}
          clientPosition="Marketplace Investment Company, Australia"
        />
      </section>

      <section className={containerStyles.onlineMarketplaceSuccessfulSection}>
        <HeadSection
          position="center"
          title="How to create a successful marketplace"
          description="User-centric approach, together with a well-thought business model, is the key to creating popular and profitable marketplaces. Having built and launched numerous e-commerce websites, our experts have gathered the best practices of the domain, and below are the steps we recommend for web development of a marketplace."
          titleLarge
          descriptionLarge
        />
        <ServicesSuccessfulSection
          imageOne={tailoredFour}
          imageTwo={tailoredFive}
          list={[
            {
              item: 'Find a niche and choose a marketplace type',
            },
            {
              item: 'Make a decision on the business model',
            },
            {
              item: 'Decide on user acquisition techniques',
            },
            {
              item: 'Validate your idea with a prototype and early users',
            },
            {
              item: 'Define the set of required features',
            },
            {
              item: 'Take care of UX/UI design',
            },
            {
              item: 'Pick the best suited platform or tech stack',
            },
            {
              item: 'Build and launch an MVP',
            },
            {
              item: 'Promote, grow and scale your product',
            },
          ]}
        />
      </section>

      <section
        className={containerStyles.onlineMarketplaceFeaturesSection}
        id="features"
      >
        <HeadSection
          position="center"
          title="What features should your marketplace have?"
          description="We advise our clients to start software development of a marketplace with a minimum viable product (MVP). Creating only the necessary functionality will enable you to quickly test your idea, and save time and budget. Marketplace websites that Codica builds are highly scalable, so any additional functionality can be easily added later on."
          descriptionLarge
        />

        <div className={containerStyles.onlineMarketplaceFeaturesSection__box}>
          <ServicesSectionBeforeTestimonials
            imageCenter={tailoredThree}
            title="Launch fast with an MVP"
            content={[
              {
                text: 'With extensive marketplace development service, Codica team can quickly build your product. To begin with, we recommend our customers to include the following minimum set of features when building an online marketplace MVP.',
              },
            ]}
            isList
            list={[
              {
                item: 'Buyers',
              },
              {
                item: 'Orders',
              },
              {
                item: 'Vendors',
              },
              {
                item: 'Payments',
              },
              {
                item: 'Inventory',
              },
              {
                item: 'Review',
              },
              {
                item: 'Products',
              },
              {
                item: 'Filters',
              },
            ]}
          />
        </div>
      </section>

      <section
        className={containerStyles.onlineMarketplaceRequirementSection}
        id="requirements"
      >
        <HeadSection
          position="center"
          title="Modern requirements for marketplace platforms"
          description="We build remarkable and high-quality custom marketplace software platforms following the best practices and industry standards."
          descriptionLarge
        />
        <ServicesRequirementsSoftware dataContent={RequirementsSoftware} />
      </section>

      <CallToAction
        title="Looking for a reliable marketplace development company?"
        subtitle="We will build a marketplace website that your users will love."
        classNameForGA="servicesCTA_2"
        linkName="Contact us"
        link="/contacts/"
      />

      <section
        className={containerStyles.onlineMarketplaceTechStackSection}
        id="tech-stack"
      >
        <HeadSection
          position="center"
          title="Perfect tech stack to create marketplace sites"
          description="Over the years, we have picked a list of tools helping us build secure, scalable and high-quality online marketplaces. Together with our efficient development process, these tools enable us to keep the cost of custom marketplace website development service within our clients’ expectations."
          descriptionLarge
        />
        <ServicesTechStackSection />
      </section>

      <section
        className={containerStyles.onlineMarketplaceSuccessStoriesSection}
        id="testimonials"
      >
        <HeadSection position="center" title="Success stories" />
        <SuccessStoriesSliderSection btnName="View all our works" />
      </section>

      <section className={containerStyles.onlineMarketplaceWhyHireSection}>
        <HeadSection
          position="center"
          title="Why you should create your online marketplace with Codica"
        />
        <CompanyWhyHireBlock dataContent={OMDWhyChoose} />
      </section>

      <section className={containerStyles.onlineMarketplaceAchievementsSection}>
        <HeadSection
          position="center"
          title="Our achievements"
          description="We are immensely thrilled to develop awesome projects. Independent ratings and research firms recognize our work."
          descriptionLarge
        />

        <AwardsServicesSection />
      </section>

      <section className={containerStyles.onlineMarketplaceClientQuotesSection}>
        <HeadSection
          position="center"
          title="Our clients say"
          description="Genuine feedback from our clients is precious to us and inspires further progress. We invite you to explore the transformative business experiences of people who entrusted their projects to Codica."
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="Richard Gunter"
            location="Israel"
            position="Founder of Impact Personal Training"
            image={clientImpact}
            quote={[
              {
                text: '“Coming from a gym environment, I had no idea how to build, create, and develop an app. Everything I needed to do was explained to me in layman’s terms. I was always up to date with where they were at on the project itself, which I found really helpful.”',
              },
            ]}
            link="fitness-progressive-web-application"
            videoLink="https://www.youtube.com/embed/r1tbx4MEmrY"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection title="Let’s build an outstanding marketplace together!" />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={OMDFAQ} />
      </section>
    </MainLayout>
  )
}

export default OnlineMarketplaces
