import { HomeSpecification } from '~/types/mock'

const OMDIndustries: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_10',
    title: 'Online marketplaces',
    description: 'Empowered with enhanced user experiences.',
    link: '/case-studies/kids-activities-service-marketplace/',
  },
  {
    icon: 'sprite3DIconSmall_13',
    title: 'eCommerce',
    description: 'Fast-loading marketplace platforms that boost sales.',
    link: '/case-studies/custom-multi-vendor-marketplace-platform/',
  },
  {
    icon: 'sprite3DIconSmall_14',
    title: 'Travel',
    description: 'Easy-to-use booking platforms to drive traffic and sales.',
    link: '/case-studies/travel-management-platform/',
  },
  {
    icon: 'sprite3DIconSmall_15',
    title: 'Automotive',
    description: 'Robust platforms for vehicle rental and sale business.',
    link: '/services/automotive-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_4',
    title: 'Insurance',
    description: 'Innovative solutions to enhance your business.',
    link: '/services/insurance-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_11',
    title: 'Recruiting',
    description: 'Intuitive and secure solutions for convenient hiring.',
    link: '/case-studies/recruitment-progressive-web-application/',
  },
  {
    icon: 'sprite3DIconSmall_16',
    title: 'Rental',
    description: 'Engaging home rental websites to disrupt the market.',
    link: '/case-studies/accommodation-search-website-mvp/',
  },
  {
    icon: 'sprite3DIconSmall_17',
    title: 'Media',
    description: 'Visually appealing media platforms that attract users.',
    link: '/case-studies/news-aggregator/',
  },
  {
    icon: 'sprite3DIconSmall_18',
    title: 'Healthcare',
    description:
      'Innovative healthcare solutions that bring profit and enhance patient care.',
    link: '/case-studies/fitness-progressive-web-application/',
  },
]

export default OMDIndustries
