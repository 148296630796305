import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useOnlineMarketplaceStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      clientImpact: file(
        relativePath: { eq: "avatars/clients/large-photo/client-impact.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      portfolioAfricar: file(
        relativePath: { eq: "homepage/discover-works-portfolio-5.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredOne: file(
        relativePath: { eq: "company-photo/company-photo-36.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredTwo: file(
        relativePath: { eq: "company-photo/company-photo-37.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredThree: file(
        relativePath: { eq: "company-photo/company-photo-38.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredFour: file(
        relativePath: { eq: "company-photo/company-photo-39.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredFive: file(
        relativePath: { eq: "company-photo/company-photo-40.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      quoteCodicaInessaPhoto: file(relativePath: { eq: "avatars/Inessa.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useOnlineMarketplaceStaticQuery
